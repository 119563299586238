<template>
  <div class="dashboard-wrapper">
    <div class="dashboard-side-bar">
      <div class="side-bar-tools" @click="addDashboard">
        <svg-icon icon-class="add" class-name="handle-icon"></svg-icon>
        <div class="title">
          {{ $t("handle.add") }}
        </div>
      </div>

      <div class="side-bar-tools" @click="toCategory">
        <svg-icon icon-class="folder" class-name="handle-icon"></svg-icon>
        <div class="title">
          {{ $t("unit.all") }}
        </div>
      </div>
    </div>

    <div class="dashboard-container">
      <router-view />
    </div>

    <el-dialog :title="$t('createDashboard')" :visible.sync="dialogVisible">
      <el-form :model="form" label-position="top" ref="form" size="small">
        <el-form-item
          :label="$t('dashboardName')"
          prop="name"
          :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('dashboardCategory')"
          prop="categoryUUID"
          :rules="[{ required: true, message: $t('rules.require'), trigger: 'change' }]"
        >
          <span slot="label">
            {{ $t("dashboardCategory") }}
            <span class="help">
              <a href="javascript:;" @click="categoryDialogVisible = true">
                {{ $t("emptyCategory") }}
              </a>
            </span>
          </span>

          <el-select v-model="form.categoryUUID" :loading="categoryLoading" filterable style="width: 100%;">
            <el-option v-for="item in categories" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit" size="small">{{ $t("handle.submit") }}</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="$t('createCategory')" :visible.sync="categoryDialogVisible" width="400px">
      <el-form :model="categoryForm" label-position="top" size="small" ref="categoryForm">
        <el-form-item
          :label="$t('categoryName')"
          prop="name"
          :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
        >
          <el-input v-model="categoryForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="categoryFormSubmit" size="small">{{ $t("handle.submit") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCategories, createCategory, createDashboard } from "api/dashboard.js";
import dashboardSVG from "@/assets/dashboard-item.svg";
import folderSVG from "@/assets/folder.svg";
export default {
  data() {
    return {
      dashboardSVG,
      folderSVG,
      dialogVisible: false,
      categoryDialogVisible: false,
      form: {
        name: "",
        organizationUUID: "",
        categoryUUID: ""
      },

      categoryForm: {
        name: "",
        organizationUUID: ""
      },

      categories: [],
      categoryLoading: false
    };
  },

  methods: {
    async getCategories() {
      this.categoryLoading = true;
      let response = await getCategories();
      this.categoryLoading = false;

      if (response.code === 0) {
        this.categories = response.data;
      }
    },

    addDashboard() {
      this.dialogVisible = true;
      this.getCategories();
    },

    toCategory() {
      let { path } = this.$route;

      if (path != "/dashboard-custom/category")
        this.$router.push({
          path: "/dashboard-custom/category"
        });
    },

    categoryFormSubmit() {
      this.$refs["categoryForm"].validate(valid => {
        if (valid) {
          this.categoryForm.organizationUUID = this.organization;
          createCategory(this.categoryForm).then(async response => {
            if (response.code === 0) {
              this.categoryDialogVisible = false;
              await this.getCategories();
              this.form.categoryUUID = response.data.uuid;
            }
          });
        }
      });
    },

    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.organizationUUID = this.organization;
          createDashboard(this.form).then(response => {
            if (response.code === 0) {
              const { fullPath } = this.$route;
              this.$nextTick(() => {
                this.$router.replace({
                  path: "/redirect" + fullPath
                });
              });
            }
          });
        }
      });
    }
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.dashboard-side-bar {
  position: fixed;
  left: 0;
  top: 50px;
  width: 60px;
  height: calc(100vh - 50px);
  box-shadow: $common-shadow;

  .side-bar-tools {
    padding: 10px 0;
    cursor: pointer;
    text-align: center;

    .title {
      font-size: 12px;
    }

    .handle-icon {
      width: 24px;
      height: 24px;
      margin: 0;
    }

    &:hover {
      background-color: $main-bg-color;
    }
  }
}

.dashboard-container {
  margin-left: 60px;
  padding-top: 10px;
}
</style>
